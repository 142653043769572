.spacer {
    width: 15px;
    height: 15px;
}

.connection-status-wrapper {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
}

.connection-status-row-maker {
    /* width: 280px; */
    width: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    background-color: 'rgba(0, 0, 0, 0)';
}

.connection-status-text-holder {
    width: 11vw;
    height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0px 0px 6px 0px;
    color: #a0a0a0;
    font-weight: 500;
    font-family: 'Montserrat';
    line-height: 22.32999px;
    text-align: right;
    letter-spacing: 0.33px;
    font-size: 18px !important;
}

.connection-status-dot {
    width: 2vh;
    border-radius: 100%;
    height: 2vh;
    margin-bottom: 2vh;
}


.top-icon{
    margin-bottom: 1vh;
}

@media all and (max-width: 810px) {
    .connection-status-text-holder {
        font-size: 16px !important;
    }

 
}

