.row-space-between {
    display: flex;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.column-spacer {
    /* display: flex;
    flex: 2; */
    min-height: 30vh;
}

.InitialPage,
.HomePage {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* background-color: #555555; */
}

/* if width is less than 900 */
@media all and (max-width: 900px) {
    .top-promotion {
        /* width: 100%; */
        font-size: large;
    }

    /* .bottom {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        position: fixed;
        background-color: white;
        bottom: 0;
    } */

    /* .bottom-row {
        width: 100%;
    } */

    .top {
        width: 100% !important;
    }

    .text-crusher {
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

}

/* if width less than 600px */
@media all and (max-width: 600px) {
    .connect-or-make-wallet-flexcolumn {
        width: 50% !important;
        min-height: min-content !important;
    }

    .connect-or-make-wallet {
        flex-direction: column !important;
        min-height: min-content !important;
        /* gap: 51px; */
    }

    .elevated-area {
        min-height: min-content !important;
        height: 100% !important;
    }

    .elevated-area-content {
        min-height: min-content !important;
        height: 100% !important;
        /* margin: 10px !important; */
    }

    .top {
        margin-top: 20px;
        width: 100% !important;
    }

    .top-promotion {
        font-size: medium;
        /* margin-bottom: 100px; */
    }


    .text-crusher {
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

/* if less than 400 */
@media all and (max-width: 400px) {
    .text-crusher {
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .elevated-area-content {
        padding: 20px !important;
    }
}

.shadow {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 98;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.overlay {
    position: fixed;
    z-index: 99;
    top: 400;
    flex: 1;
    background-color: #FFFFFF;
    margin: 10px;
    padding: 10px;
    text-shadow: none;
    color: black;

    /* a gradient border */
    /* border: 30px solid;
    border-image: linear-gradient(to right, #555, #eee) 1;
    border-radius: 100px; */

    border: solid 0px #555;
    border-radius: 30px;
    background-image: linear-gradient(white, white),
        linear-gradient(to right, grey, lightgrey);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.overlay-content {
    margin: 30px;
}


.overlay-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.btn-lnk {
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    padding-right: 10px;
    padding-left: 10px;
}

.btn-lnk a {
    color: black;
    text-decoration: none;
}

.create-new-wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.important-text {
    color: #aa0000;
    padding-bottom: 6px;
    padding-top: 6px;
}

.polygon-promotion-notice {
    padding-bottom: 6px;
    padding-top: 6px;
    width: 100%;
}

.top-promotion {
    /* white text on colored background stuck to the top of the page */



    /* position: fixed; */
    /* position: absolute; */
    z-index: 99;
    /* top: 0;
    left: 0; */
    margin-top: -2vh;
    background-color: rgb(78, 144, 219);
    padding: 10px;
    text-shadow: none;
    color: white;
    width: calc(100vw - 20px);
    text-align: center;
}

.elevated-area {
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25) !important;
    text-shadow: none;
    box-shadow: 5px 5px 10px;
    display: flex !important;
    width: 50%;
    align-items: center;
    justify-content: center;


    border: solid 0px #555;
    border-radius: 30px;
    background-image: linear-gradient(white, white),
        linear-gradient(to right, grey, lightgrey);
    background-origin: border-box;
    background-clip: content-box, border-box;

}

.elevated-area-content {
    margin: 30px;
    flex: 1;
    align-items: center;
    /* background-color: red; */

    min-height: min-content;
}

.thin-horizontal-line {
    /* width: 1065px; */
    height: 1px;
    background: #E5E5E5;
}

.top {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.bottom {
    /* display: flex; */
    /* flex-direction: column; */
    width: 90vw;
    /* position: sticky; */
    /* position: absolute; */
    background-color: white;
    /* bottom: 0; */
    margin-top: auto;
}

.bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.text-restriction {
    width: 50%;
    margin: auto;
    margin-bottom: 10px;
}

@media all and (max-width: 1500px) {
    .elevated-area {
        width: 95%;
    }

    .top {
        width: 95%;
    }

    /* .bottom {
        width: 95%;
    } */

    .text-restriction {
        width: 90%;
    }
}

.token-list,
.activity-list {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.activity-list {
    background-color: #eeeeee;
    height: 250px;
    overflow: auto;
    margin: 20px;
    text-align: justify;
    padding: 20px;
    border-radius: 10px;
}

.token-list table,
.activity-list table {
    border-collapse: collapse;
    width: 100%;
}

.token-list tr,
.activity-list tr {
    border-bottom: 1px solid #000;
}

.token-list td,
.activity-list td {
    text-align: center;
}

.integrity-check {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

/* Connect Wallet and New Wallet buttons padding and shadow fix */
.connect-wallet-button-label {
    padding-bottom: 50px;
    width: 194px;
    height: 16px;
    align-self: stretch;
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 30.5px;
    text-align: center;
    letter-spacing: 0.52px;
    text-transform: capitalize;
}

.bVEUgq {
    box-shadow: 3px 3px 0px 0px rgb(0 0 0) !important;
}

.jDtfcG {
    cursor: pointer;
}

/* header line-height adjustmnet */
/* .gRkOPB {
    width: 164px!important;
    font-size: 52px!important;
    line-height: 20px!important;
} */

/* Wallet header adjustment */
/* .gVUJOU {
    font-size: 18px!important;
} */

/* william could not find the component styled by this */
.fvHHWv {
    align-items: center !important;
}

/* .fzQwmC{
    font-size: 22px!important;
} */

/* MetaMask button */
/* .coxbKT {
    width: 250px !important;
    height: 77px !important;
    left: 9px !important;
    top: 18px !important;
} */

.metamask-connect {
    width: 379px;
    height: 124px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0);


}

/* william couldnt figure this one out either... its related to the mm button tho */
.bvzeyv {
    cursor: pointer;
    margin-top: 10px;
    width: 260px !important;
    height: 73px !important;
}

.imcMFc,
.metamask-wrap {
    width: 276px !important;
    height: 114px !important;
}

/* .dzLBHc{
    width: 230px !important;
    height: 38px !important;
    left: 25px !important;
    top: 7px !important;
    font-size: 16px !important;
} */

/* Footer adjustments */
.iVnAbI,
.footer-element {
    margin-top: -12px !important;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
}

.CVWOa {
    margin-bottom: 15px !important;
    font-size: 16px !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    margin-top: -10px !important;
}

.checkbox-did-read-wrap {
    margin-bottom: 10px;
}

/* make stuck to the very bottom of the page */
.the-very-bottom {
    position: absolute;
    bottom: 0;
}

.unstyled-links {
    text-decoration: none;
    color: #000;
}

.file-upload-button {
    width: 210px;
    height: 152px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    align-items: center;
    padding: 0px;
    border-width: 0px;
    border-radius: 40px;
    box-sizing: content-box;
    background: none;
    box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    background-color: #3C7CDC;
}

.file-upload-button-parent :hover {
    opacity: 70%;
}

.file-upload-label {
    padding-bottom: 50px;
    width: 194px;
    height: 16px;
    align-self: stretch;
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 30.5px;
    text-align: center;
    letter-spacing: 0.52px;
    text-transform: capitalize;
}

.plus-sign {
    width: 50px;
    height: 50px;
}

.connect-to-anchor-wallet-text {
    font-size: 22px !important;
    font-weight: 500;
    font-family: Montserrat;
    text-align: center;
    letter-spacing: 0.84px;
}

.metamask-image {
    left: 7px;
    top: 11px;
    position: absolute;

    width: 250px !important;
    height: 77px !important;
    left: 9px !important;
    top: 18px !important;
}

.connect-to-text {
    width: 230px;
    height: 38px;
    left: 74px;
    top: 4px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 29.899999380111694px;
    text-align: center;
    letter-spacing: 2.08px;

    width: 230px !important;
    height: 38px !important;
    left: 25px !important;
    top: 7px !important;
    font-size: 16px !important;
}

.metamask-btn-background {
    width: 375px;
    height: 108px;
    border-width: 2px;
    border-radius: 23px;
    border-style: solid;
    border-color: #000000;
    box-shadow: 3px 3px 0px 1px #000000;

    width: 250px !important;
    height: 77px !important;
    left: 9px !important;
    top: 18px !important;
}

.connect-to-mm-btn-flexcolumn {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.connect-or-make-wallet {
    gap: 4vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    --vertical-margin: 9vh;
    margin-top: var(--vertical-margin);
    margin-bottom: var(--vertical-margin);
}

.connect-or-make-wallet-flexcolumn {
    width: 210px;
    height: 157px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.new-wallet-txt {
    width: 194px;
    height: 16px;
    align-self: stretch;
    font-size: 26px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 30.5px;
    text-align: center;
    letter-spacing: 0.52px;
    text-transform: capitalize;
}

.new-wallet-wrap {
    gap: 7px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 11px 10px;
    background-size: cover;

    /* // new */
    width: 210px;
    height: 152px;
    align-self: flex-end;
    padding: 0px;
    border-width: 2px;
    border-color: black;
    border-style: solid;
    border-radius: 40px;
    box-sizing: content-box;
    cursor: pointer;
    background-color: #ffffff;

}

.plus-sign {
    margin-top: 10px;
    margin-bottom: -10px;
}

.chain-select-wrap {
    width: 90%;
    border-radius: 10px;
    border-style: solid;
    padding: 10px;
    display: flex;
}

.chain-select-wrap select {
    width: 80%;
    flex: 1;
    border: none;
}

.relevent-addresses-page ul {
    list-style-type: circle;
}


.relevent-addresses-page li {
    display: block;
}

.centered-text {
    text-align: center;
}

.small-bottom-margin {
    margin-bottom: 10px;
}

table.admin-table {
    text-align: center;
}

.ConnectionStatus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.metamask-connect img {
    margin-top: -10px !important;
}

.simple-hoz-center {
    margin: auto;
}

.wizard-output-item-value {
    margin-left: 10px;
}

.unstyled-link a {
    text-decoration: none;
    color: inherit;
}

.collection-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.collection-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.collection-detail-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-style: solid;
    border-width: 3px;
    padding: 6px;
    border-radius: 10px;
    border-color: #3C7CDC;
    /* a LIGHTER SHADE OF THE BORDER */
    background-color: #7da3dd;
    color: white;
}

.token-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.token-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.token-detail-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-style: solid;
    border-width: 3px;
    padding: 6px;
    border-radius: 10px;
    border-color: #3C7CDC;
    /* a LIGHTER SHADE OF THE BORDER */
    background-color: #7da3dd;
    color: white;
}

.scroll-area {
    overflow-y: scroll;
    height: 300px;
}

.collection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 20px;
}

.collection-item {
    margin-left: calc(1vw);
    margin-right: calc(1vw);
}

.row-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.row-list-item {
    margin-left: calc(1vw);
    margin-right: calc(1vw);
}

.vertical-separator {
    width: 1px;
    height: calc(1vh);
    background-color: black; 
}

#anchor-wallet-logo-img {
    width: 10vw;
    height: 10vh;
}

.small-margin {
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: 1vw;
    margin-right: 1vw;
}

.medium-margin {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
}