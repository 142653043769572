.anchor-logo {
  color: #3b7bdb;
  font-size: 52px;
  font-weight: 700;
  font-family: Montserrat;

  letter-spacing: -5.8px;
}

.anchor-logo-subtext {
  background-color: black;
  color: white;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: normal;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 3px;
  margin-top: -6px;
}